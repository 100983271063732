



















































































































































































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import Breadcrumb from '@/components/Breadcrumb.vue'
import PressList from '@/components/PressList.vue'
import ServicesSlider from '@/components/ServicesSlider.vue'
import PressObj from '@/interfaces/PressObj'
import UrgentObj from '@/interfaces/UrgentObj'
import { getDotDate } from '@/lib/dateModules'
import { getTopicList, getUrgentList } from '@/lib/listModules'

@Component({
  components: {
    Breadcrumb,
    PressList,
    ServicesSlider
  }
})
export default class Top extends Vue {
  topicsList: Array<PressObj> = []
  urgentList: Array<UrgentObj> = []

  async mounted(): Promise<void> {
    this.topicsList = await getTopicList()
    this.urgentList = await getUrgentList()
  }

  getDotDate(yymmdd_nn: string): string {
    return getDotDate(yymmdd_nn)
  }
}
